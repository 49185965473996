



























import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { sectionCoreProps } from '@/mixins/sectionCore'
import SectionBasic from '@/components/SectionBasic.vue'
import BlockButtons from '@/components/BlockButtons.vue'
import { ButtonAction } from '@/contracts/ButtonHeroAction'

export default defineComponent({
  name: 'SectionOsagoHelp',
  components: { BlockButtons, SectionBasic },
  mixins: [sectionCoreProps],
  props: {
    title: {
      type: String,
      default: '',
    },
    actionButtons: {
      type: Array as PropType<ButtonAction[]>,
      default: () => [],
    },
  },
})
